/** @format */
import {
  computedFrom,
  inject
} from 'aurelia-framework';

import assetsService from 'services/api/assetsService';
import poiService, {
  zonesService,
  POI,
  Zones
} from 'services/api/areasService';
import telemetryService from 'services/api/telemetryService';
import eventService, {
  EventsList
} from 'services/eventService';
import uiService from 'services/uiService';
import storageService from 'services/storageService';
import LOG from 'services/loggerServices';
import {
  TranslationService
} from 'services/translationService';
import {
  ContextActions
} from './elements/map-context/map-context';
import Utils from 'utils/utils';

import * as Actions from 'components/cctv/cctv-store-manager/actions.js';

import { Store } from 'aurelia-store';

import './track.scss';

/**
 * This is Main page view model, the default page for the app
 */
@inject(TranslationService, Store)
export class Track {
  constructor(_TranslationService, store) {
    this._assetsService = assetsService;
    this._uiService = uiService;
    this._poiService = poiService;
    this._zonesService = zonesService;
    this._telemetryService = telemetryService;
    this._eventService = eventService;
    this._ts = _TranslationService

    this.store = store;
    this.actions = Actions;

    this.errorMessage = {
      message: '',
      button: {
        label: '',
        action: ''
      },
      mediaSettings: null
    };

    //members
    this.mapProvider = this._uiService.mapProvider;
    this.mapAssets = null;
    this.mapTelemetry = null;
    this.mapPOI = null;
    this.mapZones = null;
    this.mapContextItem = null;
    this.mapCenter = null;
    this.mapCenterZoom = null;
    this.mapCenterBounds = null;
    this.mapShapes = null;
    this.mapBoundsPadding = (storageService.get('UI:ContextList')) ? [0, 0, 0, 415] : [0, 0, 0, 0];
    this.mapAutoFitBounds = true;
    this.mapShapesEditable = false;
    this.mapReady = false;

    this.listClosed = true;
    this.listAction = null;
    this.listAssets = [];
    this.listPOI = [];
    this.listZones = [];

    this.contextList = [{
      type: 'asset',
      label: 'fleet',
      icon: 'fi-car',
    },
    {
      type: 'poi',
      label: 'places',
      icon: 'fi-map-marker',
    }
    ];

    this.storageHistory = (storageService.get('UI:SearchHistory')) ? JSON.parse(storageService.get('UI:SearchHistory')) : [];
    this.searchHistory = (storageService.get('UI:SearchHistory')) ? JSON.parse(storageService.get('UI:SearchHistory')) : [];

    this.trackSearchValue = '';
    this.contextSearchValue = '';
    this.onSearchClear = this.onSearchClear.bind(this);
    this.onSearchListSelected = this.onSearchListSelected.bind(this);

    this.onAssetListSelected = this.onAssetListSelected.bind(this);
    this.onAssetListGroupSelected = this.onAssetListGroupSelected.bind(this);

    this.onPOIListSelected = this.onPOIListSelected.bind(this);
    this.onPOIListGroupSelected = this.onPOIListGroupSelected.bind(this);
    this.onPOIAddressSelected = this.onPOIAddressSelected.bind(this);

    this.onListAction = this.onListAction.bind(this);
    this.onReturnToContextList = this.onReturnToContextList.bind(this);
    //
    this.onMapReady = this.onMapReady.bind(this);
    this.onMapContextAction = this.onMapContextAction.bind(this);
    this.updateFleetStatus = this.updateFleetStatus.bind(this);
    this.updateFleetStatusEnabled = false;

    //drawing stuff
    this.newMapShape = null;
    this.onMapOption = this.onMapOption.bind(this);
  }

  attached() {
    let contextList = storageService.get('UI:ContextList');
    if (contextList) {
      let contextListContext = JSON.parse(contextList);
      this.onListAction('openList', contextListContext)
    }

    this._eventService.subscribe(
      EventsList.MapContextTripPoint,
      this.onMapContextTripPoint
    );

    this._eventService.subscribe(
      EventsList.MapServiceAssetSelected,
      this.onMapAssetSelected
    );

    this._eventService.subscribe(
      EventsList.MapServiceZoneSelected,
      this.onMapZoneSelected
    );

    this._eventService.subscribe(
      EventsList.MapServicePOISelected,
      this.onMapPOISelected
    );

    this._eventService.subscribe(
      EventsList.MapServiceZoomChanged,
      this.onMapZoomChanged
    );

    this._eventService.subscribe(
      EventsList.POICreated,
      this.onPOICreated
    );

    this._eventService.subscribe(
      EventsList.POIChanged,
      this.onPOIChanged
    );

    this.store.state.subscribe((state) => {
      if (state.alert) {
        this.errorMessage.message = state.alert.message;
        this.errorMessage.button.label = state.alert.button.label;
        this.errorMessage.button.action = state.alert.button.action;
      }
    });
  }

  detached() {
    this.toggleFleetUpdates(false);

    this._eventService.unsubscribe(
      EventsList.MapContextTripPoint,
      this.onMapContextTripPoint
    );

    this._eventService.unsubscribe(
      EventsList.MapServiceAssetSelected,
      this.onMapAssetSelected
    );

    this._eventService.unsubscribe(
      EventsList.MapServiceZoneSelected,
      this.onMapZoneSelected
    );

    this._eventService.unsubscribe(
      EventsList.MapServicePOISelected,
      this.onMapPOISelected
    );

    this._eventService.unsubscribe(
      EventsList.MapServiceZoomChanged,
      this.onMapZoomChanged
    );

    this._eventService.unsubscribe(
      EventsList.POICreated,
      this.onPOICreated
    );

    this._eventService.unsubscribe(
      EventsList.POIChanged,
      this.onPOIChanged
    );
  }

  @computedFrom('_uiService.userSettings.mapSimpleTrail')
  get mapTelemetryOptions() {
    return {
      simpleTrail: this._uiService.userSettings.mapSimpleTrail
    };
  }

  onMapReady() {
    this.mapReady = true;
    this.loadData();
  }

  onMapAssetSelected = asset => {
    this.updateSearchHistory(asset, 'asset');
    this.setContextState(asset);
  };

  onMapZoneSelected = zone => {
    this.onZoneListSelected(zone);
  };

  onMapPOISelected = poi => {
    this.onPOIListSelected(poi);
  };

  onPOICreated = poi => {
    this.listPOI.push(poi);

    this.listPOI = this.listPOI.slice(0);
    this.mapPOI = this.listPOI.slice(0);
  };

  onPOIChanged = poi => {
    if (!poi) return;
    let currentPOIList = this.listPOI.find(x => x.id === poi.id);
    if (currentPOIList) {
      currentPOIList.name = poi.name;
    }

    this.listPOI = this.listPOI.slice(0);
    this.mapPOI = this.listPOI.slice(0);
  };

  onSearchClear() {
    storageService.remove('UI:ContextList');
    this.setContextState(null, null, false);
    //remove location marker from map
    //todo: refactor mapservice location marker, move it from map.js to a bindable property
    this.onPOIAddressSelected(null);
    //reload assets in case there was a filter applied
    this.loadAssets(true);
  }

  onSearchListSelected(item) {
    if (item && item._name) {
      switch (item._name) {
        case 'asset':
          this.onAssetListSelected(item, null);
          break;
        case 'poi':
          this.onPOIListSelected(item);
          break;
      }
    }
  }

  onListAction(action, context) {
    switch (action) {
      case 'filter':
        if (context.type == 'asset') {
          this.mapAssets = Utils.filterArrayObj(
            this.mapAssets,
            'name,formattedName,groupName,shortAddress',
            this.contextSearchValue
          );
        } else if (context.type == 'poi') {
          this.mapPOI = Utils.filterArrayObj(
            this.mapPOI,
            'name,code,formattedAddress,groupName',
            this.contextSearchValue
          );
        }
        break;
      case 'unfilter':
        if (context.type == 'asset') {
          this.mapAssets = this.listAssets.slice(0);
        } else if (context.type == 'poi') {
          this.mapPOI = this.listPOI.slice(0);
        }
        break;
      case 'openList':
        storageService.set('UI:ContextList', JSON.stringify(context));
        this.listAction = context
        this.listClosed = false;
        this.trackSearchValue = '';
        if (this.mapBoundsPadding[3] == 0) {
          this.mapBoundsPadding = [0, 0, 0, 415];
        }
        break;
    }
  }

  onReturnToContextList() {
    this.setContextState(null, null, false);
    this.onPOIAddressSelected(null);
    this.loadData();
    this.trackSearchValue = '';

    let contextList = storageService.get('UI:ContextList');
    if (contextList) {
      let contextListContext = JSON.parse(contextList);
      this.onListAction('openList', contextListContext)
    }
  }

  onAssetListSelected(asset, action) {
    if (action === 'preview') {
      //tODO refactor this
      let focused = asset.id === this.listPreviewItem;

      this.listPreviewItem = focused ? null : asset.id;
      this.setMapFocus(focused ? null : asset);
      return;
    }

    if (asset) {
      this.updateSearchHistory(asset, 'asset');
      this.listPreviewItem = null;
      let pLoadAsset = asset ?
        this._assetsService.getById(asset.id) :
        Promise.resolve(null);
      pLoadAsset.then(_asset => this.setContextState(_asset, true, false));
    }
    this.setContextState(asset);
  }

  onAssetListGroupSelected(group) {
    if (!group) {
      this.mapCenterBounds = null;
      this.setMapFocus(null);
      return;
    }
    this.mapCenterBounds = (group.items || [])
      .filter(a => a.latlng)
      .map(a => a.latlng);
  }

  onPOIListSelected(poi) {
    this.mapShapesEditable = false;
    if (poi) {
      this.updateSearchHistory(poi, 'poi');
      this._poiService.get(poi.id).then(_poi => this.setContextState(_poi));
    } else {
      this.setContextState(poi);
    }
  }

  onPOIListGroupSelected(group) {
    if (!group) {
      this.mapCenterBounds = null;
      this.setMapFocus(null);
      return;
    }
    this.mapCenterBounds = (group.items || [])
      .filter(p => p.latlng)
      .map(p => p.latlng);
  }

  onZoneListSelected(zone) {
    this.mapShapesEditable = false;
    if (zone) {
      // this.updateSearchHistory(zone.name, 'zone');
      this._zonesService.get(zone.id).then(_zone => this.setContextState(_zone));
    } else {
      this.setContextState(zone);
    }
  }

  onPOIAddressSelected(address) {
    this._eventService.publish(EventsList.MapLocationMarker, address);
    if (address) {
      this.setContextState(address);
    }
  }

  loadData() {
    this.loadPOI();
    this.loadZones();
    this.loadFleet();
  }

  loadFleet() {
    this.loadAssets(true);
    this.toggleFleetUpdates();
  }

  setContextState(item, force, focus = true) {
    if (
      !force &&
      this.mapContextItem &&
      item &&
      this.mapContextItem.id === item.id
    ) {
      return;
    }

    let state = !!item;
    if (!this.listClosed && !state) {
      this.mapBoundsPadding = [0, 0, 0, 0];
    }
    this.listClosed = !state;
    if (!state) {
      this.listAction = null;
    }

    this.mapContextItem = item;
    this.mapETA = item ? item.eta : null;

    //in case is editing a poi
    this.mapTelemetry = null;
    this.mapShapes = null;
    this.mapRoute = null;
    this.newMapShape = null;
    if (!item) {
      this.mapShapesEditable = false;
    }

    if (focus) {
      this.setMapFocus(item);
    }
  }

  /**
   * Load initial map telemetry
   */
  loadTelemetry(id, dtfrom, dtto, autoFit = true) {
    this.toggleFleetUpdates(false);
    return this._telemetryService
      .getPositions(id, dtfrom, dtto)
      .then(telemetry => {
        this.mapAutoFitBounds = autoFit;
        this.mapAssets = null;
        this.mapTelemetry = telemetry;
        return telemetry;
      });
  }

  loadAssets(autoFit) {
    return this._assetsService.getAll().then(assets => {
      this.mapAutoFitBounds = autoFit;
      this.mapAssets = assets;
      this.listAssets = assets;
    });
  }

  loadPOI() {
    this._poiService.get().then(poi => {
      this.mapPOI = poi;
      this.listPOI = poi;

      this.mapShapes = null;
      this.mapPOI.forEach(p => {
        if (p.geometry) {
          this.mapShapes.push(p.geometry)
        }
      })
    });
  }

  loadZones() {
    this._zonesService.getSimplified(true).then(zones => {
      this.mapZones = [];
      this.listZones = [];

      if (zones) {
        let zonesList = (!(zones instanceof Array)) ? [zones] : zones
        zonesList.forEach(zone => {
          this.mapZones.push(zone);
          this.listZones.push(zone);
        });
      }
    });
  }

  setMapFocus(item, zoom = 17) {
    this.focusedItem = item;
    if (item) {
      this.mapCenterZoom = zoom;
      this.mapCenter = item.latlng;
      if (item.geometry) {
        this.mapShapes = [item.geometry];
      }
      this.toggleFleetUpdates();
    } else {
      this.mapTelemetry = null;
      if (!this.mapAssets) {
        this.loadFleet();
      } else {
        this.toggleFleetUpdates();
        this.mapAssets = this.mapAssets.slice(0)
      }
    }
  }

  onMapContextAction(action, args = {}) {
    switch (action) {
      case ContextActions.trail:
      case ContextActions.trip: {
        let {
          dtto,
          dtfrom,
          id
        } = args;
        return this.loadTelemetry(id, dtfrom, dtto);
      }
      case ContextActions.focus: {
        let qLoad = args.skipLoad ?
          Promise.resolve(true) : this.loadAssets(false);

        return qLoad.then(() => {
          this.setContextState(args.asset, true);
        });
      }
      case ContextActions.edit:
        this.mapShapesEditable = args.editableShapes;
        this.setContextState(args.item, true);
        break;
      case ContextActions.poi:
        this.createPOI(args.address);
        break;
      case ContextActions.draw:
        this.mapShapes = null;
        this.newMapShape = null; //clear old one to trigger `changed` event
        if (args.shape) {
          setTimeout(() => (this.newMapShape = args.shape), 0);
        }
        break;
      case ContextActions.nearbyRoute:
        this.mapRoute = args.route;
        this.mapShapes = null;
        break;
      case ContextActions.close:
        //find a better place for this..
        this.mapShapesEditable = false;
        this.setContextState(args.item, args.focus || true);
        break;
      case ContextActions.eta:
        this.mapETA = args.eta;
        break;
      case ContextActions.alert: {
        let {
          alert
        } = args;
        this.loadTelemetry(
          alert.assetId,
          alert.startGpsTimestamp,
          alert.endGpsTimestamp
        );
      }
        break;
      default:
        LOG.warn(
          `onMapContextAction. A valid action was not found. [${action}]`
        );
    }

    return Promise.resolve(true);
  }

  onMapZoomChanged = zoom => {
    if (zoom <= 11) {
      //fire Evdnt
      this.listPreviewItem = null;
      this.focusedItem = null;
    }
  };

  onMapOption(option, params) {
    if (option === 'location') {
      this.setContextState(params.address, true);
    }
  }

  onMapContextTripPoint = latlng => {
    this.mapCenterZoom = 17;
    this.mapCenter = latlng;
  };

  updateFleetStatus(update) {
    //update map asset snapshot data
    let asset = (this.mapAssets || []).find(a => a.id === update.id);
    if (asset) {
      asset.updateState(update);
      this.mapAssetsUpdates = [asset];

      //follow item if was in focus
      if (this.focusedItem && this.focusedItem.id === asset.id && this.focusedItem._name === asset._name) {
        this.setMapFocus(asset, null);
        this.mapETA = asset.eta;
      }
    }
  }

  toggleFleetUpdates(start = true) {
    if (start && !this.updateFleetStatusEnabled) {
      this._eventService.subscribe(EventsList.SignalrbroadcastSnapshot, this.updateFleetStatus);
      this.updateFleetStatusEnabled = true;
    }

    if (!start && this.updateFleetStatusEnabled) {
      this._eventService.unsubscribe(EventsList.SignalrbroadcastSnapshot, this.updateFleetStatus);
      this.updateFleetStatusEnabled = false;
    }
  }

  createPOI(address) {
    if (address) {
      let [lat, lng] = address.latlng;
      let poiAddress = address.formattedAddress;
      let name =
        address.components.street ||
        address.components.city ||
        address.components.district ||
        address.components.postCode;
      let newpoi = new POI({
        center: [lng, lat],
        formattedAddress: poiAddress,
        name: name || ' '
      });

      this.mapShapesEditable = true;
      this.setContextState(newpoi, true);
    }
  }

  updateSearchHistory(value, type) {
    this.storageHistory.unshift({
      name: value.name,
      id: value.id,
      type: type,
    })
    this.searchHistory.unshift({
      name: value.name,
      id: value.id,
      type: type,
    })
    this.storageHistory.splice(20)
    this.searchHistory.splice(20)

    storageService.set('UI:SearchHistory', JSON.stringify(this.storageHistory));
  }
}
